import React from 'react'
import connectEmail from '../../static/connect-email.svg'
import connectFacebook from '../../static/connect-facebook.svg'
import connectInsta from '../../static/connect-insta.svg'
import connectTwitter from '../../static/connect-twitter.svg'
import contactStyles from './contactStyles.module.css'

const Contact = props => {
  return(
  <div className={contactStyles.contactContainer}>
  <div className={contactStyles.contactContainerImg}>
    <a href="mailto:editor@horburyosettnews.co.uk"><img src={connectEmail} alt="Horbury Ossett News Email"/></a>
  </div>
  <div className={contactStyles.contactContainerImg}>
    <a href="https://www.instagram.com/horburyossettnews/"><img src={connectInsta} alt="Horbury Ossett News Instagram"/></a>
  </div>
  <div className={contactStyles.contactContainerImg}>
    <a href="https://www.facebook.com/horburyossettnews/"><img src={connectFacebook} alt="Horbury Ossett News Facebook"/></a>
  </div>
  <div className={contactStyles.contactContainerImg}>
  <a href="https://twitter.com/HorburyOssett/"><img src={connectTwitter} alt="Horbury Ossett News Twitter"/></a>
</div>
  </div>
  )
}


export default Contact
